import { faker, Faker } from '@faker-js/faker';
import {
  PurchaseInvoiceDetailed,
  InvoiceTypeEnum,
  InvoiceStatusEnum,
  CurrencyEnum,
} from '~/api/data-contracts';
import { mockOrganization } from './mockOrganization';
import { mockInvoiceDocument } from './mockInvoiceDocument';
import { mockTransactionInPurchaseInvoice } from './mockTransactionInPurchaseInvoice';
import { mockOrderRef } from './mockOrderRef';

export const mockPurchaseInvoiceDetailed = (
  f: Faker = faker,
): Required<PurchaseInvoiceDetailed> => ({
  id: f.string.uuid(),
  invoiceType: InvoiceTypeEnum.Purchase,
  number: f.string.alphanumeric(10),
  status: f.helpers.arrayElement(Object.values(InvoiceStatusEnum)),
  order: f.string.uuid(),
  orderRef: mockOrderRef(),
  invoicingDate: f.date.recent().toISOString().split('T')[0],
  company: mockOrganization(f),
  chargesTotalSumInInvoiceCurrency: f.finance.amount({
    min: 100,
    max: 10000,
    dec: 2,
  }),
  invoiceCurrency: f.helpers.arrayElement(Object.values(CurrencyEnum)),
  baseCurrencyRate: f.finance.amount({ min: 0.5, max: 2, dec: 6 }),
  invoiceDocument: f.datatype.boolean() ? mockInvoiceDocument(f) : null,
  transaction: mockTransactionInPurchaseInvoice(f),
});
