import { faker, Faker } from '@faker-js/faker';
import {
  Currency,
  CurrencyEnum,
  CurrencyHistoricalRatesDto,
} from '~/api/data-contracts';
import dayjs from '@/utils/dayjs';

export const mockCurrencyHistoricalRatesDto = (
  f: Faker = faker,
): CurrencyHistoricalRatesDto => ({
  base: Currency.EUR,
  rates: Object.values(CurrencyEnum).reduce(
    (acc, currency) => {
      acc[currency] = 1;
      return acc;
    },
    {} as Record<CurrencyEnum, number>,
  ),
  timestamp: dayjs(f.date.recent()).unix(),
});
